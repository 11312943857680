import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { useParams, Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Button, Form, Input, Select, Space, Table, Tag } from 'antd';
import { PageContainer, PageHeader } from '@ant-design/pro-components';
import {
  fetchAccountingItems,
  searchAccountingItemsHandler,
  selectAccountingItems,
  selectAccountingLoading
} from '../../../../redux/hwcAccountingSlice';
import { AccountingItem, AccountingItemSearchQuery } from '../../../../custom_types/redux-types';
import DownloadCSVButton from '../components/DownloadCSVButton';

const HwcAccountingManagePage = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const loading = useSelector(selectAccountingLoading);
  const { id } = useParams<{ id: string }>();
  const items = useSelector(selectAccountingItems);

  useEffect(() => {
    dispatch(fetchAccountingItems(id));
  }, [dispatch, id]);

  const breadcrumbEles = [
    { key: '1', path: '/accounting', breadcrumbName: '对账记录' },
    {
      key: '2',
      path: '',
      breadcrumbName: `账目清单`
    }
  ];

  //   const itemRender = (route: Route, params: any[], routes: Route[], paths: string[]) => {
  //     console.log(paths);
  //     const last = routes.indexOf(route) === routes.length - 1;
  //     return last ? (
  //       <span key={route.breadcrumbName}>{route.breadcrumbName}</span>
  //     ) : (
  //       <Link key={route.breadcrumbName} to="/accounting">
  //         {route.breadcrumbName}
  //       </Link>
  //     );
  //   };

  const searchRecords = async () => {
    form.validateFields().then((values: any) => {
      const searchValues: AccountingItemSearchQuery = {
        ...values
      };
      console.log('search values:', searchValues);
      dispatch(searchAccountingItemsHandler(id, searchValues));
    });
  };

  const colums = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (_: any, __: any, index: number) => index + 1
    },
    {
      title: '订单日期',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (date: Date | string) => {
        if (!date || date === '-') return '-';
        return (
          <>
            <div>{dayjs(date).format('YYYY/MM/DD')}</div>
            <div>{dayjs(date).format('HH:mm:ss')}</div>
          </>
        );
      }
    },
    {
      title: '物流渠道',
      dataIndex: 'channel',
      key: 'channel'
    },
    {
      title: '客户名称',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '订单号',
      dataIndex: 'orderId',
      key: 'orderId'
    },
    {
      title: '平台单号',
      dataIndex: 'erpOrderId',
      key: 'erpOrderId'
    },
    {
      title: '物流单号',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber'
    },
    {
      title: '官方序号',
      dataIndex: 'pieceId',
      key: 'pieceId'
    },
    {
      title: '邮寄州',
      dataIndex: 'uspsState',
      key: 'uspsState'
    },
    {
      title: '重量',
      dataIndex: 'weight',
      key: 'weight',
      render: (weight: number, record: AccountingItem) => {
        return `${weight} ${record.weightType}`;
      }
    },
    {
      title: '分区',
      dataIndex: 'zone',
      key: 'zone'
    },
    {
      title: '物流底价',
      dataIndex: 'baseShippingFee',
      key: 'baseShippingFee'
    },
    {
      title: '应收物理费',
      dataIndex: 'shippingFee',
      key: 'shippingFee'
    },
    {
      title: '付款物流费',
      dataIndex: 'servicePayment',
      key: 'servicePayment'
    },
    {
      title: '操作费',
      dataIndex: 'operationFee',
      key: 'operationFee'
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => {
        return status === 0 ? <Tag color="green">对账完成</Tag> : <Tag color="error">对账失败</Tag>;
      }
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark'
    },
    {
      title: '账单编号',
      dataIndex: 'docName',
      key: 'docName'
    }
  ];

  const generateCSVData = (data: AccountingItem[]) => {
    return data.map((record, index) => {
      return {
        序号: index + 1,
        账单归属: record.userName,
        订单日期: dayjs(record.orderDate).format('YYYY/MM/DD HH:mm:ss'),
        重量: record.weight.toFixed(2),
        重量单位: record.weightType,
        邮寄州: record.uspsState,
        官方序号: record.pieceId,
        订单号: record.orderId,
        平台单号: record.erpOrderId,
        面单号: record.trackingNumber,
        分区: record.zone,
        底价: `$ ${record.baseShippingFee}`,
        物流费: `$ ${record.shippingFee}`,
        操作费: `$ ${record.operationFee}`
      };
    });
  };

  return (
    <>
      <PageContainer
        fixedHeader
        header={{
          title: '账目清单',
          breadcrumb: { items: breadcrumbEles }
        }}
        extra={[
          <Button key="create" type="primary" icon={<SearchOutlined />} onClick={searchRecords}>
            查询
          </Button>,
          <DownloadCSVButton
            label="导出账单"
            data={generateCSVData(items)}
            fileName="对账单"
            header="Serial number, User, Order date, Weight, Weight unit, USPS state, Official serial number, Order number, Platform order number, Tracking number, Zone, Base price, Shipping fee, Service payment, Operation fee"
          />
        ]}
      >
        <Form form={form} layout="horizontal">
          <Space direction="horizontal" size="middle">
            <Form.Item label="用户" name="userName">
              <Input type="text" placeholder="用户" />
            </Form.Item>
            <Form.Item label="订单号" name="orderId">
              <Input type="text" placeholder="订单号" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="平台单号" name="erpOrderId">
              <Input type="text" placeholder="平台单号" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="状态" name="status">
              <Select style={{ width: '200px' }}>
                <Select.Option value="">所有状态</Select.Option>
                <Select.Option value="0">对账完成</Select.Option>
                <Select.Option value="1">对账失败</Select.Option>
              </Select>
            </Form.Item>
          </Space>
          <Space direction="horizontal" size="middle">
            <Form.Item label="面单号" name="trackingNumber">
              <Input type="text" placeholder="面单号" style={{ width: 300 }} />
            </Form.Item>
            <Form.Item label="账单编号" name="docName" style={{ width: 600 }}>
              <Input type="text" />
            </Form.Item>
          </Space>
        </Form>
        <Table<AccountingItem> columns={colums} dataSource={items} loading={loading} />
      </PageContainer>
    </>
  );
};

export default HwcAccountingManagePage;
