import { Tabs } from 'antd';
import React, { ReactElement } from 'react';
import MabangErpOrderPanel from './MabangErpOrderPanel';
import { OverseaWarehouse } from '../../../../../custom_types/wms-page';
import HwcFulfillOrderPanel from './HwcFulfillOrdersPanel';

interface HwcFulfillOrdersProps {
  hwcClient: OverseaWarehouse;
}

const HwcFulfillOrders = ({ hwcClient }: HwcFulfillOrdersProps): ReactElement => {
  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition="left"
      style={{ height: 220 }}
      items={[
        {
          label: '订单管理',
          key: '1',
          children: <HwcFulfillOrderPanel hwcClient={hwcClient} />
        },
        {
          label: '代发货申请',
          key: '2',
          children: 'Create Order'
        },
        {
          label: '马帮ERP操作',
          key: '3',
          children: <MabangErpOrderPanel hwcClientCode={hwcClient.code} />
        }
      ]}
    />
  );
};

export default HwcFulfillOrders;
